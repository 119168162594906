<template>
  <div
    class="p-relative ratio drop-zone rounded border cursor-pointer"
    style="overflow: hidden;"
    @click="target"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    :style="ratio ? 'padding-bottom: ' + padding + '%;' : ''"
  >
    <slot></slot>

    <input
      type="file"
      ref="file"
      @change="onChange"
      hidden
      :multiple="multiple"
      :accept="type ? type.join(',') : ''"
    />
  </div>
</template>

<script>
export default {
  props: {
    ratio: String,
    type: [String, Array],
    multiple: Boolean,
    maxSize: Number
  },
  computed: {
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },
  data() {
    return {
      loaded: false,
      files: []
    };
  },
  mounted() {
    this.eventListener(
      document,
      "drag dragstart dragend dragover dragenter dragleave drop",
      e => {
        e.preventDefault();
        e.stopPropagation();
      }
    );

    this.eventListener(this.$el, "dragover dragenter", () =>
      this.onDragStart()
    );
    this.eventListener(this.$el, "dragleave dragend drop", () =>
      this.onDragStop()
    );
    this.eventListener(this.$el, "drop", e => this.onDrop(e));
  },
  methods: {
    onDragStart() {
      this.$emit("dragenter");
      this.$el.classList.add("drag-over");
    },

    onDragStop() {
      this.$emit("dragleave");
      this.$el.classList.remove("drag-over");
    },

    addEvent(obj, type, fn) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, false);
      } else if (obj.attachEvent) {
        obj.attachEvent("on" + type, function() {
          return fn.call(obj, window.event);
        });
      }
    },

    eventListener(el, events, listener) {
      events = events.split(" ");

      events.map(event => {
        this.addEvent(el, event, listener);
      });
    },

    clear() {
      this.$refs["file"].value = [];
      this.files = [];
    },

    onDrop(e) {
      e.preventDefault();

      if (e.dataTransfer.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i++)
          if (e.dataTransfer.items[i].kind === "file")
            this.addFile(e.dataTransfer.items[i].getAsFile());
      }

      this.$emit("input", this.files);
      this.$emit("drop", this.files);
      this.$emit("change", this.multiple ? this.files : this.files[0]);

      this.clear();
    },

    onChange() {
      let files = this.$refs["file"].files;

      if (files) {
        Object.keys(files).map(i => {
          this.addFile(files[i]);
        });

        this.$emit("input", this.files);
        this.$emit("change", this.multiple ? this.files : this.files[0]);
      }

      this.clear();
    },

    target(event) {
      this.$refs["file"].click();
      this.$emit("click", event);
    },

    addFile(file) {
      if (this.type === undefined) {
        this.$emit("file", file);
        this.files.push(file);
      } else if (this.type === file.type || this.type.includes(file.type)) {
        this.$emit("file", file);
        this.files.push(file);
      }
    }
  }
};
</script>
