var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "p-relative ratio drop-zone rounded border cursor-pointer",
      staticStyle: { overflow: "hidden" },
      style: _vm.ratio ? "padding-bottom: " + _vm.padding + "%;" : "",
      on: {
        click: _vm.target,
        blur: function($event) {
          return _vm.$emit("blur", $event)
        },
        focus: function($event) {
          return _vm.$emit("focus", $event)
        }
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("input", {
        ref: "file",
        attrs: {
          type: "file",
          hidden: "",
          multiple: _vm.multiple,
          accept: _vm.type ? _vm.type.join(",") : ""
        },
        on: { change: _vm.onChange }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }